<template>
	<createReleaseLayout>
		<div class="upload-music create_music_release" :class="{'disabled': tracksUploading}">

			<div class="container">

				<h3 class="page-title">
					Upload music
				</h3>

				<!-- Progress-Line -->
				<StepNavigation 
					:currentStep="CURRENT_STEP"
					:id="id"
					:hasTracks="prepareUploadTracks.length"
					:uploading="tracksUploading"
					:hasCoverImage="clipperAvatar"
					:changingAvatar="changeAvatarLoader"
					:isFree="publishFree"
				/>

				<!-- album details -->
				<template v-if="CURRENT_STEP === 1">
					<section class="card">
						<h2 class="name_section">Release info</h2>
						<div class="body">

							<div class="details full_width">
								<div class="item_row">
									<v-text-field
										:class="{'field_error': $v.details.title.$error || RELEASE_INFO_ERROR_MESSAGE === 'artist_already_have_release_with_title',
										'disabled': CREATED_ALBUM && CREATED_ALBUM.beenPublished }"
										v-model="details.title"
										label="Title"
										:readonly="CREATED_ALBUM && CREATED_ALBUM.beenPublished || false"
										required
										@input="$v.details.title.$touch()"
										@blur="$v.details.title.$touch()"
										@keydown="blockTitleInput"
									></v-text-field>
									<span class="error-msg" v-if="$v.details.title.$error && !$v.details.title.maxLength">{{ details.title.length }}/50</span>
									<span class="error-msg" v-if="$v.details.title.$error && !$v.details.title.required">Title is required.</span>
									<span class="error-msg" v-if="RELEASE_INFO_ERROR_MESSAGE === 'artist_already_have_release_with_title'">
                                        Release with this name already exists under the chosen artist account.
                                    </span>
								</div>

								<div class="item_row">
									<v-textarea
										v-model="details.description"
										:class="{'field_error': $v.details.description.$error}"
										couner
										label="Description"
										required
										auto-grow
										@input="$v.details.description.$touch()"
										@keydown="blockDescriptionTextarea"
										@blur="$v.details.description.$touch()">
									</v-textarea>
									<span class="error-msg"
									      v-if="$v.details.description.$error && !$v.details.description.maxLength">{{ details.description.length }}/2000</span>
									<!--									<span class="hist-message" v-if="$v.details.description.maxLength">{{ details.description.length }}/2000</span>-->
								</div>

								<!-- TAGS -->
<!--								<div class="wrap_tags">-->
<!--									<div class="input-style-2 tags" :class="{'field_error': $v.details.tags.$error}">-->
<!--										<div>-->
<!--											<v-chip v-for="(item, index) in details.tags" :key="index" close class="mx-1 my-3"-->
<!--											        @click:close="details.tags.splice(index,1)">{{ item }}-->
<!--											</v-chip>-->
<!--											<input type="text" placeholder="Tags" @keydown="addTag($event)" v-model="newTag"-->
<!--											       @keyup.delete="removeTag($event)" :disabled="details.tags.length >=5">-->
<!--										</div>-->
<!--										<div class="count_tags">{{ details.tags.length }}/5</div>-->
<!--									</div>-->
<!--									<span class="error-msg" v-if="$v.details.tags.$error">Tags is required.</span>-->
<!--								</div>-->


								<div class="row_fields">
									<!-- GENRE -->
									<div class="dropdown_style dropdown_disable" :class="{'disabled': !GENRES.length}">
										<v-menu offset-y content-class="navy_blue" v-model="genresDropdown" :close-on-click="true" disabled
										        :close-on-content-click="true">
											<template v-slot:activator="{ on, attrs }">
												<div class="dropdown_inside" v-bind="attrs" v-on="on">
													<!--													<div type="text"-->
													<!--													       :class="{'field_error': $v.details.genre.id.$error}"-->
													<!--													       @focus="genresDropdown = true" @keydown="keyHandler">-->
													<div>{{ details.genre.name }}</div>

													<!--													<input type="text" required v-model="details.genre.name"-->
													<!--													       :class="{'field_error': $v.details.genre.id.$error}"-->
													<!--													       @focus="genresDropdown = true" @keydown="keyHandler">-->

													<label :class="{'active': details.genre.id}">Genre</label>
												</div>
											</template>

											<!--											<v-list v-if="genresList.length">-->
											<!--												<v-list-item v-for="item of genresList" :key="item.id" @click="setGenre(item)"-->
											<!--												             :class="{'active': item.id === details.genre.id}">-->
											<!--													<button type="button" v-ripple>{{ item.name }}</button>-->
											<!--												</v-list-item>-->
											<!--											</v-list>-->
										</v-menu>
										<!--										<span class="error-msg" v-if="$v.details.genre.id.$error">Genre is required.</span>-->
									</div>

									<!-- SUBGENRE -->
									<div class="dropdown_style" :class="{'disabled': !subGenresList.length}">
										<v-menu offset-y content-class="navy_blue" v-model="subGenresDropDown" :close-on-click="true"
										        :close-on-content-click="true">
											<template v-slot:activator="{ on, attrs }">
												<div class="dropdown_inside" v-bind="attrs" v-on="on">
													<input type="text" required v-model="details.subgenre.name"
													       :class="{'field_error': $v.details.subgenre.id.$error}"
													       @focus="subGenresDropDown = true" @keydown="keyHandler">
													<label :class="{'active': details.subgenre.id}">Sub Genre</label>
												</div>
											</template>

											<v-list v-if="subGenresList.length">
												<v-list>
													<v-list-item v-for="item in subGenresList" :key="item.id" @click="setSubgenre(item)"
													             :class="{'active': item.id === details.subgenre.id}">
														<button type="button" v-ripple>{{ item.name }}</button>
													</v-list-item>
												</v-list>
											</v-list>
										</v-menu>
										<span class="error-msg" v-if="$v.details.subgenre.id.$error">Sub Genre is required.</span>
									</div>

									<!-- RELEASE DATE -->
									<!-- :close-on-click="true" -->
									<div class="dropdown_style on_change">
										<v-menu
											v-model="releaseDateDatePicker"
											:close-on-content-click="false"
											ref="releaseDateDatePicker"
											transition="scale-transition"
											offset-y
											max-width="290px"
											min-width="auto">
											<template v-slot:activator="{ on, attrs }" v-on="on">
												<div class="dropdown_inside" v-bind="attrs" v-on="on">
													<input type="text" v-model="dateFormatted" :class="{'field_error': $v.datePicker.$error}"
													       @focus="releaseDateDatePicker = true"><!-- @keydown="keyHandler" @input="customFormatDate" -->
													<label :class="{'active': datePicker}">Release Date</label>
												</div>
											</template>
											<v-date-picker
												v-model="datePicker"
												no-title
												width="320"
												show-adjacent-months
												ok-text="OK"
												cancel-text="Cancel"
												:weekday-format="getDay">
											</v-date-picker>
											<!--											@input="releaseDateDatePicker = false"-->
										</v-menu>
										<span class="error-msg" v-if="$v.datePicker.$error">Release Date is required.</span>
									</div>
								</div>

								<!-- ACCOUNTS -->
								<div class="row_fields left">

									<!-- Accounts -->
									<div class="dropdown_style" @click="setAccount" v-if="!details.label.id && !details.artist.id">
										<div class="dropdown_inside">
											<input type="text" @click="setAccount">
											<label :class="{'active': details.label.title}">Account</label>
										</div>
									</div>

									<!-- LABEL -->
									<div class="dropdown_style" @click="confirmChangeLabel" v-if="details.label.id">
										<div class="dropdown_inside" :class="{filled: details.label.id}">
											<input type="text" v-model="details.label.title" @click="confirmChangeLabel">
											<label :class="{'active': details.label.title}">Record Label</label>
											<button type="button" v-if="details.label.id" class="clear_input" @click.stop="clearInput('label')"></button>
										</div>
									</div>

									<!-- Artist -->
<!--									<div>{{VARIOUS_ARTISTS}}</div>-->
									<div class="dropdown_style" v-if="VARIOUS_ARTISTS">
										<div class="dropdown_inside" @click="confirmChangeAccount" :class="{filled: VARIOUS_ARTISTS === true}">
											<input type="text" @click="confirmChangeAccount" :value="'Various artists'" @keydown="keyHandler" />
											<label class="active" @click="confirmChangeAccount">Account</label>
											<button type="button" class="clear_input" @click.stop="clearInput('artist')"></button>
										</div>
									</div>
									<div class="dropdown_style" v-if="(details.artist.id || details.label.id) && !VARIOUS_ARTISTS">
										<div class="dropdown_inside" @click="confirmChangeAccount" :class="{filled: details.artist.id}">
											<input type="text" required v-model="details.artist.name" @click="confirmChangeAccount"
											       :class="{'field_error': $v.details.artist.name.$error || (details.artist.name && !artistsList.length)}"
											       @keydown="keyHandler">
											<label :class="{'active': details.artist.name}" @click="confirmChangeAccount">Account</label>
											<button type="button" v-if="details.artist.id" class="clear_input" @click.stop="clearInput('artist')"></button>
										</div>
										<span class="error-msg" v-if="$v.details.artist.name.$error">Account is required.</span>
										<span class="error-msg" v-if="artistSubscriptionError">Error artist subscription</span>
									</div>

								</div>
							</div>

							<div class="message_error release_info" v-if="RELEASE_INFO_ERROR_MESSAGE !== 'artist_already_have_release_with_title'">
								{{ RELEASE_INFO_ERROR_MESSAGE }}
							</div>
						</div>
					</section>

					<div class="step_actions">
						<button type="button" class="back disabled" v-ripple @click="changeCurrentStep(1)">Back</button>

						<!-- Next btn-->
						<button type="button" class="next primary_btn" v-ripple
						        @click="saveReleaseInfo('continue')">
							<span v-if="!createLoading">Next</span>
							<v-progress-circular v-if="createLoading" :width="2" indeterminate></v-progress-circular>
						</button>

					</div>
				</template>

				<!-- tracks && Cover Image-->
				<template v-if="CURRENT_STEP === 2">

					<section class="card">
						<h2 class="name_section">Files
							<span>Drag to change order</span>
						</h2>

						<div class="body">
							<draggable v-if="prepareUploadTracks.length" v-model="prepareUploadTracks" class="list-group uploaded-tracks" :move="canMoveTrack"
							           tag="div"
							           v-bind="dragOptions" @start="isDragging = true" @end="isDragging = false"
							           @change="changeTrackPosition(prepareUploadTracks, $event)">
								<div v-for="(track, index) in prepareUploadTracks" :key="index" class="uploaded-tracks-item"
								     :class="{'upload-failure': track.isLosslessUploaded === false}">
									<div class="_name" :class="{'track_uploaded': track.id, 'track-more': details.artist.id }">
										<span class="label">Track: {{ index + 1 }}</span>

										<input type="text" class="value" v-model="track.title" @keydown="renameTrack"
										       :readonly="CREATED_ALBUM.beenPublished || false">
										<v-progress-linear
											v-if="track.progress > 0"
											v-model="track.progress"
											color="#20C4F5"
											height="2">
										</v-progress-linear>
										<div v-if="track.id && track.isLosslessUploaded" class="is_uploaded_track"></div>

										<div class="upload_status">
											<span class="progress" v-if="!track.success && track.progress">{{ track.progress }}%</span>
											<span class="upload_success" v-if="track.success === true"></span>
											<span class="delete_track" @click="confirmDeleteTrack(index, track.id)"
                                                  :class="{'delete-process': trackDeleteProgress(track.id)}"
											      v-if="(editRelease !== true || !CREATED_ALBUM.beenPublished) && uploadedTracks && !track.progress">
                                                <v-progress-circular v-if="trackDeleteProgress(track.id)" :width="1" indeterminate></v-progress-circular>
                                            </span>
										</div>
									</div>
									<div class="_artist" v-if="!details.artist.id">
										<span class="label">Artist</span>
										<span class="value">
											<input type="text" required v-on:keypress="numbersOnly" v-model="track.bpm">
										</span>
									</div>

									<div class="_bpm">
										<span class="label">BPM</span>
										<span class="value">
                                            <input type="text" required v-on:keypress="numbersOnly" v-model="track.bpm">
                                        </span>
									</div>
									<div class="_key">
										<span class="label">Key</span>
										<span class="value" @click="showKeys(track, index)">
                                            <input type="text"  :value="getKeyCode(track)" id="track-key">
                                        </span>
									</div>
								</div>
							</draggable>

							<div class="wrap_keys filter_keys" v-if="prepareUploadTracks.length && showTrackKeysPanel" v-click-outside="onClickOutsideKeys"
							     :style="{position: 'absolute', 'top': positionKeysPanel + 'px'}">
								<div class="b_section">
									<div v-for="key in songKeysGroupB" :key="key.code" @click="select_key(key)"
									     :class="{'active': isSelectedKey(showTrackKeysPanelIndex, key)}">
										<div class="_mark">{{ key.code }}</div>
										<div class="_name">{{ key.name }}</div>
									</div>
								</div>
								<div class="a_section">
									<div v-for="key in songKeysGroupA" :key="key.code" @click="select_key(key)"
									     :class="{'active': isSelectedKey(showTrackKeysPanelIndex, key)}">
										<div class="_mark">{{ key.code }}</div>
										<div class="_name">{{ key.name }}</div>
									</div>
								</div>
							</div>

							<div class="upload-controls" v-if="editRelease !== true || !CREATED_ALBUM.beenPublished">
								<input
									type="file"
									hidden
									multiple
									ref="uploadForm"
									accept=".wav, .flac"
									@change="handleTracksUpload($event)">
								<button type="button" v-ripple class="primary_btn large" @click="selectTrackFile">Select</button>
								<span class="description_">200 MB limit per track <br>WAV, FLAC<br>
                                    <span class="error-msg tracks" v-if="$v.prepareUploadTracks.$error">*Required.</span>
                                </span>

							</div>
						</div>
					</section>

					<section class="card">
						<h2 class="name_section">Cover</h2>
						<div class="body">
							<CoverImageUploader
								:album-image="albumDetails.smallImage"
								:validation-error="$v.clipperAvatar.$error"
								:clipper-avatar="clipperAvatar"
								:valid-image="validImage"
								:scale-image-width="scaleImageWidth"
								:scale-image-height="scaleImageHeight"
								:upload-image-loader="uploadImageLoader"
								:clipper-dialog.sync="clipperDialog"
								:scale="scale"
								@photo-upload="photoUpload"
								@close-dialog="closeClipperDialog"
								@change-image="changeAva"
								@clip-image="clipImage"
								@handle-zoom="handleZoom"
								@update-dimensions="updateDimensions"
								ref="coverImageUploader"
							/>
							<div class="error-message" v-if="errorMessage">{{ errorMessage }}</div>
						</div>
					</section>

					<div class="step_actions">
						<button type="button" class="back" v-ripple @click="changeCurrentStep(1)">Back</button>

						<button type="button" class="next primary_btn" v-ripple @click="saveFiles('continue')">
							<span v-if="!createLoading">Next</span>
							<v-progress-circular v-if="createLoading" :width="2" indeterminate></v-progress-circular>
						</button>

					</div>
				</template>

				<!-- Commerce -->
				<template v-if="CURRENT_STEP === 3">
					<!-- Commerce -->
					<section class="card">
						<h2 class="name_section">Commerce</h2>
						<div class="body">

							<template v-if="MUSIC_RELEASE_COMMERCE_LOADER === true || LOADER_GET_STRIPE_ACCOUNT === true">
								<v-skeleton-loader
									type="image"
									class="skeleton_album_price"
								></v-skeleton-loader>
							</template>

							<template v-if="MUSIC_RELEASE_COMMERCE_LOADER === false && LOADER_GET_STRIPE_ACCOUNT === false">
								<div class="publish_album_free" :class="{'active': publishFree}">
									<div class="material_checkbox">
										<input type="checkbox" id="publish-free" v-model="publishFree" required>
										<label for="publish-free">Publish this album as free for all under
											<a href="https://creativecommons.org/licenses/by-nc-sa/4.0/" target="_blank">Creative Commons License</a>
										</label>
									</div>

									<div class="alert_text" v-if="publishFree">
										Your album will be published free of charge and supporters will not be able to pay for it.
										To allow supporters to pay what they<br> want, set the Album Price to $0
									</div>
									<div class="error-msg err-checkbox" v-if="$v.publishFree.$error">*Required</div>
								</div>

								<!-- STRIPE - x (Problem)-->
								<div class="accept_payments" v-if="STRIPE_ACCOUNT && STRIPE_ACCOUNT.status !== 'complete'">

									<!-- No Account -->
									<template v-if="STRIPE_ACCOUNT && STRIPE_ACCOUNT.status === 'no-account'">
										<span>To accept payments</span>
										<button type="button" class="c2a_btn large" @click="createStripe">
											Get Stripe
											<span class="arrow">
                                            <span class="line_svg"></span>
                                            <svg width="7" height="10" viewBox="0 0 7 10" fill="none">
                                                <path d="M1.5 1.5L5 5L1.5 8.5" stroke="white" stroke-width="2" stroke-linecap="square"/>
                                            </svg>
                                        </span>
										</button>
									</template>

									<!-- Restricted -->
									<template v-if="STRIPE_ACCOUNT && STRIPE_ACCOUNT.status === 'restricted'">
										<div class="wrap_stripe_status">
											<div class="stripe_status restricted">
												There is a problem with your Stripe account.
												<button type="button" @click="resolveStripeIssue">Click here</button>
												to resolve the issue
											</div>
										</div>
									</template>

									<!-- Pending -->
									<template v-if="STRIPE_ACCOUNT && STRIPE_ACCOUNT.status === 'pending'">
										<div class="stripe_status no-account">
											Your registration with Stripe is being reviewed. We will notify you when theres an update.
											<button type="button" @click="visitStripe" :class="{loading: LOADER_GET_STRIPE_ACCOUNT_LINK}">
												Notification preferences
												<v-progress-circular :width="2" indeterminate></v-progress-circular>
											</button>
										</div>
									</template>
								</div>

								<!-- Stripe - Complete -->
								<div class="wrap_album_price" v-if="!publishFree && STRIPE_ACCOUNT && STRIPE_ACCOUNT.status === 'complete'">

									<!-- Album Price -->
									<div class="row_price">
										<div class="special_input" :class="{err: albumPrice > 1000}">
											<span class="_label_">Album Price</span>
											<div class="_price_">
												<span>$</span>
												<input type="text" class="input_album_price" v-on:keypress="numbersOnly"
												       @keyup="albumPriceLimit" v-model="albumPrice" v-price-format="albumPrice">
											</div>
										</div>

										<label for="">Set the price $0 to let supporters pay what they want</label>
									</div>

									<!-- Select Allow Tracks -->
									<div class="allow_tracks">
										<div class="material_checkbox">
											<input type="checkbox" id="allow_tracks" v-model="allowTracksChecked" @change="changeAllowTracksChecked">
											<label for="allow_tracks">Allow tracks to be sold individually</label>
										</div>
									</div>

									<!-- Tracks List -->
									<div class="tracks_list" v-if="allowTracksChecked">
										<div v-for="(track, index) of MUSIC_RELEASE_COMMERCE.songs" :key="track.id" class="special_input item_track"
										     :class="{err: track.price > 100}">
											<span class="_label_">{{ track.title }}</span>
											<div class="_price_">
												<span>$</span>
												<input type="text" v-on:keypress="numbersOnly" @keyup="trackPriceLimit($event, index)" v-model="track.price"
												       @blur="onBlurTrackPrice(track, index)">
											</div>
										</div>
									</div>
								</div>
							</template>

						</div>
					</section>

					<div class="step_actions">
						<button type="button" class="back tetriatary_btn" v-ripple @click="changeCurrentStep(2)">Back</button>

						<template
							v-if="(publishFree && STRIPE_ACCOUNT && STRIPE_ACCOUNT.status !== 'complete') || ((publishFree || albumPrice) && STRIPE_ACCOUNT && STRIPE_ACCOUNT.status === 'complete')">
							<button type="button" class="next primary_btn" v-ripple @click="saveCommerce('continue', 'default')"
							        v-if="!CREATED_ALBUM.beenPublished">
								<span v-if="!createLoading">Next</span>
								<v-progress-circular v-if="createLoading" :width="2" indeterminate></v-progress-circular>
							</button>
							<button type="button" class="next primary_btn" v-ripple @click="saveCommerce('publish', 'default')"
							        v-if="CREATED_ALBUM.beenPublished">
								<span v-if="!createLoading">Publish</span>
								<v-progress-circular v-if="createLoading" :width="2" indeterminate></v-progress-circular>
							</button>
						</template>
						<template v-else>
							<div class="next primary_btn disable">
								<template v-if="!CREATED_ALBUM.beenPublished">Next</template>
								<template v-if="CREATED_ALBUM.beenPublished">Publish</template>
							</div>
						</template>
					</div>
				</template>


				<!-- DIALOGS -->
				<newAccount v-if="modalCreateNewAccount" v-model="modalCreateNewAccount" :uses="'createRelease'" :viewListAccounts="viewListAccounts"></newAccount>
				<comingSoonDialog v-if="comingSoonDialogDialog" v-model="comingSoonDialogDialog"></comingSoonDialog>
				<warningChangeAlbum v-if="warningChangeAlbumDialog" v-model="warningChangeAlbumDialog" :albumID="id"></warningChangeAlbum>

				<!-- photo limit dialog -->
				<v-dialog v-if="photoLimitDialog"
				          v-model="photoLimitDialog"
				          max-width="600">
					<div class="need_login_dialog style_dialog">
						<h2 class="header">
							Oops!
						</h2>
						<div class="cnt">
							Image does not fit criteria, please choose a JPG or PNG photo at least 1,000 pixels in width and length as well as less than 10 mb
							in size.
						</div>
						<div class="wrap_actions">
							<button type="button" class="ok" @click="changeAva" v-ripple>Ok</button>
							<button type="button" class="cancel" @click="photoLimitDialog = false" v-ripple>Cancel</button>
						</div>
					</div>
				</v-dialog>

				<!-- file(track) limit dialog -->
				<v-dialog v-if="trackLimitDialog"
				          v-model="trackLimitDialog"
				          max-width="600">
					<div class="need_login_dialog style_dialog">
						<h2 class="header">
							Oops!
						</h2>
						<div class="cnt">
							Please select a file smaller than 200MB.
						</div>
						<div class="wrap_actions center">
							<button type="button" class="tetriatary_btn" @click="trackLimitDialog = false" v-ripple>Ok</button>
							<!--							<a v-if="FACEBOOK" :href="FACEBOOK" target="_blank" class="primary_btn" v-ripple>Contact Support</a>-->
						</div>
					</div>
				</v-dialog>

				<v-dialog v-if="uploadLimitDialog"
				          v-model="uploadLimitDialog"
				          :content-class="'style4'"
				          max-width="600">
					<h2 class="header_dialog">
						Upload Limit
					</h2>
					<div class="cnt_dialog center">
						50 files per release
					</div>
					<div class="wrap_actions_dialog center">
						<button type="button" class="tetriatary_btn" @click="uploadLimitDialog = false" v-ripple>Ok</button>
						<a :href="DISCORD" target="_blank" class="primary_btn" v-ripple>Contact Support</a>
					</div>
				</v-dialog>

				<!-- Limit Album-Price -->
				<v-dialog
					v-if="albumPriceLimitDialog"
					v-model="albumPriceLimitDialog"
					max-width="570"
					:content-class="'price_limit_dialog'">
					<h2 class="header_dialog">
						Price limit
					</h2>
					<div class="cnt_dialog">
						If you'd like to price your release over $1,000<br> please contact support
					</div>
					<div class="wrap_actions_dialog">
						<button type="button" class="tetriatary_btn" @click="albumPriceLimitDialog = false" v-ripple>Cancel</button>
						<!--						<button type="button" class="primary_btn" @click="albumPriceLimitDialog = false" v-ripple>Contact Support</button>-->
						<a v-if="FACEBOOK" :href="FACEBOOK" target="_blank" class="primary_btn" v-ripple>Contact Support</a>
					</div>
				</v-dialog>

				<!-- Limit Track-Price -->
				<v-dialog
					v-if="trackPriceLimitDialog"
					v-model="trackPriceLimitDialog"
					max-width="570"
					:content-class="'price_limit_dialog'">

					<h2 class="header_dialog">
						Price limit
					</h2>
					<div class="cnt_dialog">
						If you'd like to price your track over $100<br> please contact support
					</div>
					<div class="wrap_actions_dialog">
						<button type="button" class="tetriatary_btn" @click="trackPriceLimitDialog = false" v-ripple>Cancel</button>
						<a v-if="FACEBOOK" :href="FACEBOOK" target="_blank" class="primary_btn" v-ripple>Contact Support</a>
					</div>
				</v-dialog>

				<!--  User Decides Price -->
				<v-dialog v-if="userDecidesPriceDialog"
				          v-model="userDecidesPriceDialog"
				          max-width="570"
				          :content-class="'style3'">

					<h2 class="header_dialog">
						User Decides Price
					</h2>
					<div class="cnt_dialog">
						Leaving the price zero allows supporters to pay what they feel is fair during the transaction.
					</div>
					<div class="wrap_actions_dialog">
						<button type="button" class="tetriatary_btn" @click="userDecidesPriceDialog = false" v-ripple>Cancel</button>
						<button type="button" class="set_to_zero" @click="setToZero">Set to zero</button>
					</div>
				</v-dialog>

				<!--  Price Distribution -->
				<v-dialog v-if="priceDistributionDialog"
				          v-model="priceDistributionDialog"
				          max-width="570"
				          :content-class="'style3'">

					<h2 class="header_dialog">
						Price distribution
					</h2>
					<div class="cnt_dialog">
						<div class="description">
							Divide the release price evenly among the tracks or choose one of the following options. Click cancel to set price manually.
						</div>

						<div class="select_distribution">
							<v-radio-group v-model="variantDistribution" column mandatory>
								<v-radio label="Round up to the nearest dollar: $1.30 → $2.00" value="ceil" color="#20C4F5" :ripple="false"></v-radio>
								<!--								<v-radio label="Round down to the nearest dollar: $1.30 → $1.00" value="floor" color="#20C4F5" :ripple="false"></v-radio>-->
								<v-radio label="Set the price of tracks to zero to let users pay what they want" value="zero" color="#20C4F5"
								         :ripple="false"></v-radio>
							</v-radio-group>
						</div>
					</div>
					<div class="wrap_actions_dialog">
						<button type="button" class="cancel tetriatary_btn" @click="priceDistributionDialog = false" v-ripple>Cancel</button>
						<button type="button" class="apply" @click="distributionAlbumPrice(variantDistribution)" v-ripple>Apply</button>
					</div>
				</v-dialog>

				<v-dialog
					v-if="warningRemoveTrackDialog"
					v-model="warningRemoveTrackDialog"
					max-width="570"
					:content-class="'warning_remove_track_dialog'">
					<div class="heading">
						<h2>Warning</h2>
						<button class="close_modal" @click="warningRemoveTrackDialog = false"></button>
					</div>
					<div class="description_modal">
						Removing this track will affect the users who purchased and or added this track to their favorites.
					</div>
					<div class="wrapper_action_modal">
						<button class="tetriatary_btn" @click="warningRemoveTrackDialog = false">Cancel</button>
						<button class="red_btn" v-ripple @click="deleteTrack(allowRemoveTracksIndex)">Continue</button>
					</div>
				</v-dialog>
				<v-dialog
					v-if="warningChangeArtistDialog"
					v-model="warningChangeArtistDialog"
					max-width="570"
					:content-class="'warning_remove_track_dialog'">
					<div class="heading">
						<h2>Warning</h2>
						<button class="close_modal" @click="warningChangeArtistDialog = false"></button>
					</div>
					<div class="description_modal">
						Changing the Artist on this release will affect the users who purchased and or added the release to their favorites.
					</div>
					<div class="wrapper_action_modal">
						<button class="tetriatary_btn" @click="warningChangeArtistDialog = false">Cancel</button>
						<button class="red_btn" v-ripple @click="modalCreateNewAccount = true; warningChangeArtistDialog = false">Continue</button>
					</div>
				</v-dialog>

                <v-dialog v-if="losslessUploadedFailedDialog"
                          v-model="losslessUploadedFailedDialog"
                          max-width="600">
                    <div class="need_login_dialog style_dialog">
                        <h2 class="header">
                            Oops!
                        </h2>
                        <div class="cnt">
                            Some tracks failed to upload.
                        </div>
                        <div class="wrap_actions center">
                            <button type="button" class="tetriatary_btn" @click="losslessUploadedFailedDialog = false" v-ripple>Ok</button>
                        </div>
                    </div>
                </v-dialog>


                <v-dialog v-if="isOffline" v-model="isOffline" :content-class="'style4 interrupted'" max-width="600">
                    <div  class="need_login_dialog style_dialog">
                        <h2 class="header">
                            Oops!
                        </h2>
                        <div class="cnt">
                            Connection interrupted. Reconnecting in {{ secondsRemaining }} seconds...
                        </div>
                        <div class="wrap_actions_dialog center">
                            <button type="button" class="next primary_btn" v-ripple @click="stopTimer">
                                <span>Retry</span>
                            </button>
                        </div>
                    </div>
                </v-dialog>
			</div>
		</div>
	</createReleaseLayout>
</template>

<script>
import axios from "axios";
import {mapActions, mapGetters, mapMutations} from "vuex";
import {maxLength, required} from "vuelidate/lib/validators";
import draggable from 'vuedraggable';
import {blockTitleInput, blockDescriptionTextarea, numbersOnly, keyHandler} from "@/utils/formValidators";
import {formatDate, convertToDatePicker, getDay} from "@/utils/dateFormatters";

import createReleaseLayout from "@/layouts/create-release-layout.vue";
import {baseUrlToBlob} from "@/utils/baseUrlToBlob";
import albumDetails from "@/pages/musicRelease/albumDetails.vue";
import login from "@/pages/auth/login.vue";
import {songKeysGroupA, songKeysGroupB} from "@/store/modules/metaData/getters";
import de from "vue2-datepicker/locale/es/de";
import StepNavigation from "@/components/musicRelease/StepNavigation.vue";
import CoverImageUploader from "@/components/musicRelease/CoverImageUploader.vue";

const comingSoonDialog = () => import('@/components/dialogs/comingSoonDialog');
const warningChangeAlbum = () => import('@/components/dialogs/warningСhangeAlbum');
const newAccount = () => import('@/components/newAccount.vue');

export default {
	name: "createMusicRelease",
	props: ['id', 'editRelease', 'create'],
	components: {
		createReleaseLayout,
		draggable,
		comingSoonDialog,
		warningChangeAlbum,
		newAccount,
		StepNavigation,
		CoverImageUploader,
	},
	data(vm) {
		return {
			// upload tracks
			tracksUploading: false,
			tracksEdited: false,
			tracksDeleted: false,
			prepareUploadTracks: [],
			uploadedTracks: [],

			// upload image
			scale: 0.5,
			clipperDialog: false,
			clipperDialogVisible: false,
			uploadedFile: '',
			clipperAvatar: '',
			changeAvatarLoader: false,
			validImage: true,
			photoLimitDialog: false,
			uploadImageLoader: false,
			scaleImageWidth: null,
			scaleImageHeight: null,

            online: navigator.onLine,


			// albumDetails
			details: {
				title: '',
				description: '',
				tags: [],
				genre: {id: 2, name: 'Psychedelic Trance'},
				subgenre: {id: '', name: ''},
				artist: {id: '', name: ''},
				label: {id: '', title: ''}
			},
			releaseInfoChanged: false,
			artistSubscriptionError: false,
            trackDeleteProgressId: undefined,

			genresDropdown: false,
			subGenresDropDown: false,
			artistDropDown: false,

			genresList: '',
			allSubGenresList: '',
			subGenresList: '',
			artistsList: '',
			recordLabelsList: '',

			newTag: '',

			// Release Date Picker
			datePicker: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
			dateFormatted: vm.formatDate((new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10)),
			releaseDateDatePicker: false,
			daysOfWeek: ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'],

			publishFree: false,
			allowTracksChecked: false,
			albumPrice: null,
			formValid: false,
			createLoading: false,
			validCommence: false,

			variantDistribution: '',

			drag: false,

			comingSoonDialogDialog: false,
			warningChangeAlbumDialog: false,
			modalCreateNewAccount: false,
			albumPriceLimitDialog: false,
			trackPriceLimitDialog: false,
			userDecidesPriceDialog: false,
			priceDistributionDialog: false,
			uploadLimitDialog: false,
			trackLimitDialog: false,
            losslessUploadedFailedDialog: false,
            isConnected: true,

			warningRemoveTrackDialog: false,
			allowRemoveTracksIndex: null,

			warningChangeArtistDialog: false,
			allowChangeArtist: false,


			showTrackKeysPanel: null,
			showTrackKeysPanelIndex: null,
			positionKeysPanel: null,

            //
            secondsRemaining: 15,
            intervalId: null,

			//
			accounts: {
				artist: null,
				label: null,
			},
			viewListAccounts: null, // label or artist

			errorMessage: '', // Error message for UI display
			_lastScaleValue: 0,
		}
	},
	validations: {
		prepareUploadTracks: {
			required
		},
		clipperAvatar: {
			required
		},
		details: {
			title: {
				required,
				maxLength: maxLength(50)
			},
			description: {
				maxLength: maxLength(2000)
			},
			tags: {
				required
			},
			genre: {
				id: {
					required,
				}
			},
			subgenre: {
				id: {
					required,
				}
			},
			artist: {
				name: {
					required
				}
			}
		},

		datePicker: {
			required
		},
		publishFree: {
			checked(val) {
				return val
			},
		},

	},
	watch: {
		id() {
			if (!this.id) {
				this.changeCurrentStep(1);
				this.details = {
					title: '',
					description: '',
					tags: [],
					genre: {id: '', name: ''},
					subgenre: {id: '', name: ''},
					artist: {id: '', name: ''},
					label: {id: '', title: ''}
				};
				this.prepareUploadTracks = [];
				this.clipperAvatar = '';
			} else {
				this.updateAlbumData();
			}
		},
		'details.title': {
			handler(oldVal, newVal) {
				if (oldVal !== newVal) {
					if (oldVal && newVal) {
						if (oldVal.length > 51) {
							return
						}
						this.releaseInfoChanged = true;
						this.$store.commit('SET_RELEASE_INFO_ERROR_MESSAGE', '');

					}
				}
			},
			deep: true
		},
		'details.description': {
			handler(oldVal, newVal) {
				if (oldVal !== newVal) {
					if (oldVal && newVal) {
						this.releaseInfoChanged = true;
						this.$store.commit('SET_RELEASE_INFO_ERROR_MESSAGE', '');
					}
				}
			},
			deep: true
		},

		// 'details.genre.id': {
		// 	handler(oldVal, newVal) {
		// 		if (oldVal !== newVal) {
		// 			if (oldVal && newVal) {
		// 				this.releaseInfoChanged = true;
		// 				this.$store.commit('SET_RELEASE_INFO_ERROR_MESSAGE', '');
		// 			}
		// 		}
		//
		// 		if (this.genresList) {
		// 			let subgenreList = this.genresList.find(el => el.id === this.details.genre.id);
		// 			this.subGenresList = subgenreList.subGenres;
		// 		}
		// 	},
		// 	deep: true
		// },
		'details.subgenre.id': {
			handler(newVal, oldVal) {
				if (this.CREATED_ALBUM && this.CREATED_ALBUM.subGenres && !this.CREATED_ALBUM.subGenres.length) {
					this.releaseInfoChanged = true;
				}

				if (oldVal !== this.details.subgenre.id) {
					if (oldVal) {
						this.releaseInfoChanged = true;
						this.$store.commit('SET_RELEASE_INFO_ERROR_MESSAGE', '');
					}
				}
			},
			deep: true
		},

		datePicker() {
			this.dateFormatted = this.formatDate(this.datePicker);
			this.releaseInfoChanged = true;
			this.releaseDateDatePicker = false;
			this.$store.commit('SET_RELEASE_INFO_ERROR_MESSAGE', '');
		},
		// dateFormatted() {
		// 	console.log('dateFormatted', this.dateFormatted);
		// 	// console.log('datePicker', this.datePicker);
		// 	this.releaseInfoChanged = true;
		// 	this.releaseDateDatePicker = false;
		// 	this.$store.commit('SET_RELEASE_INFO_ERROR_MESSAGE', '');
		// 	this.datePicker = this.convertToDatePicker(this.dateFormatted);
		// 	console.log('datePicker', this.datePicker);
		// },
		SELECT_ARTIST() {
			if (this.SELECT_ARTIST) {
				this.details.artist.id = this.SELECT_ARTIST.id;
				this.details.artist.name = this.SELECT_ARTIST.name;
				this.artistSubscriptionError = false;
			}
		},
		// 'SELECTED_LABEL': {
		// 	handler(oldVal, newVal) {
		// 		console.log('watch SELECTED_LABEL' + oldVal + ' ' + newVal);
		// 		if (this.SELECTED_LABEL) {
		// 			this.details.label.id = this.SELECTED_LABEL.id;
		// 			this.details.label.title = this.SELECTED_LABEL.title;
		// 		}
		// 	},
		// 	deep: true
		// },
		SELECTED_LABEL() {
			if (this.SELECTED_LABEL) {
				this.details.label.id = this.SELECTED_LABEL.id;
				this.details.label.title = this.SELECTED_LABEL.title;
			}
		},

		WARNING_CHANGE_STATUS() {
			if (this.WARNING_CHANGE_STATUS === true) {
				this.$refs.uploadForm.click();
				this.$store.commit('SET_WARNING_CHANGE_STATUS', false);
			}
		},
		scale(val) {
			console.log('Scale watcher triggered with value:', val);
			// Avoid unnecessary recursion
			if (val === this._lastScaleValue) {
				console.log('Skipping duplicate scale update');
				return;
			}
			
			// Validate scale bounds
			let newScale = val;
			if (!newScale || newScale < 0.5) {
				newScale = 0.5;
			}
			if (newScale > 2) {
				newScale = 2;
			}
			
			// Store the value we're processing to avoid recursion
			this._lastScaleValue = newScale;
			
			// Only update the model if the value actually changed
			if (newScale !== val) {
				console.log('Correcting scale from', val, 'to', newScale);
				this.scale = newScale;
				return; // The watcher will be called again with the corrected value
			}

			try {
				// Use the getClipper method to access the clipper
				const clipperComponent = this.$refs.coverImageUploader;
				const clipper = clipperComponent ? clipperComponent.getClipper() : null;
				
				if (clipper) {
					console.log('Updating clipper with scale:', newScale);
					clipper.setWH$.next(newScale);
					let clipperImage = clipper.clip();
					this.scaleImageWidth = clipperImage.width;
					this.scaleImageHeight = clipperImage.height;
				} else {
					console.warn('Clipper component not found');
				}
			} catch (error) {
				console.error('Error updating zoom scale:', error);
				// Display error to user
				this.$set(this, 'errorMessage', 'Error updating image zoom. Please try again.');
				// Clear error after 3 seconds
				setTimeout(() => {
					this.$set(this, 'errorMessage', '');
				}, 3000);
			}
		},
		clipperDialog() {
			if (this.clipperDialog === false) {
				this.$refs.uploadedFile.value = null;
			}
		},
		CURRENT_STEP() {
			if (this.CURRENT_STEP === 3) {
				if (!this.STRIPE_ACCOUNT) {
					this.getStripeData();
				}
			}
		},
		albumPrice() {
			this.releaseInfoChanged = true;
		},
        isConnected() {
            if (this.isConnected) {
                this.updateAlbumData();
            }
        },
        isOffline() {
            this.isOffline ? this.stopTimer() : this.startTimer();
        }
	},
	computed: {
		de() {
			return de
		},
		albumDetails() {
			return albumDetails
		},
		...mapGetters([
			'CREATED_ALBUM', 'CREATED_ALBUM_LOADING',

			'userID', 'isAdmin', 'GENRES', 'YOUR_ARTISTS', 'YOUR_RECORD_LABELS', 'PREPARE_TRACK', 'FILE_URL',
			'TEMP_CREATED_ALBUMS_ID', 'TEMP_PLAYLIST_ID', 'ARTIST',

			'ALBUM_IMAGE_STATUS',
			'UPLOAD_TRACK',
			'CURRENT_STEP',
			'CHANGE_ALBUM',
			'WARNING_CHANGE_STATUS',
			'RELEASE_INFO_ERROR_MESSAGE',
			'FROM_PAGE',

			// 'SELECTED_ARTIST_ID',
			'CHANGE_SELECTED_ARTIST',
			'CHANGE_SELECTED_LABEL',
			'SELECT_ARTIST',
			'SELECTED_LABEL',
			'VARIOUS_ARTISTS',

			'FACEBOOK',
			'DISCORD',

			// commerce && stripe
			'MUSIC_RELEASE_COMMERCE',
			'MUSIC_RELEASE_COMMERCE_LOADER',
			'STRIPE_ACCOUNT',
			'CREATE_LINK_STRIPE_ACCOUNT',
			'STRIPE_ACCOUNT_LINK',
			'LOADER_GET_STRIPE_ACCOUNT',
			'LOADER_GET_STRIPE_ACCOUNT_LINK',

            //Song Keys Getters
            'songKeysGroupA',
            'songKeysGroupB'
		]),
		dragOptions() {
			return {
				animation: 200,
				group: "description",
				disabled: false,
				ghostClass: "ghost"
			};
		},

        isOffline() {
            return !this.isConnected
        }
	},
	created() {
		if (this.$route.params.id) {
			// this.mode = 'edit';
		} else {
			// this.mode = 'create';
		}
		document.addEventListener("visibilitychange", this.handleVisibilityChange);
        window.addEventListener('online', this.updateOnlineStatus);
        window.addEventListener('offline', this.updateOnlineStatus);

		this.$watch(
			() => this.$route.params.id,
			(newId, oldId) => {
				if (newId === undefined && oldId !== undefined) {
					// mode create release
					this.changeCurrentStep(1);
					this.$store.commit('SET_TEMP_CREATED_ALBUMS_ID', null);
					this.$store.commit('SET_CREATED_ALBUM', '');
				}
			}
		);
		if (this.$route.query.redirect_status === 'succeeded') {
			this.modalCreateNewAccount = true;
		}
		if (this.FROM_PAGE.name === 'albumDetails' && this.CURRENT_STEP === 3) {
			this.changeCurrentStep(3);
			if (!this.STRIPE_ACCOUNT) {
				this.getStripeData();
			}
		} else {
			this.changeCurrentStep(1); // 1
		}
		this.$store.commit('SET_WARNING_CHANGE_STATUS', false);
		this.getGenres();

		this.GET_YOUR_ARTISTS({owner: this.userID, deletedAt: false, 'order[createdAt]': 'desc'})
			.then(() => {
				this.artistsList = this.YOUR_ARTISTS;
			})
			.catch(err => console.error(`GET_YOUR_ARTISTS, ${err}`));

		this.GET_YOUR_RECORD_LABELS({
			owner: this.userID,
			sort: 'createdAt',
			order: 'desc'
		})
			.then(() => {
				this.recordLabelsList = this.YOUR_RECORD_LABELS;
			})
			.catch(err => console.error(`GET_YOUR_RECORD_LABELS, ${err}`));

		if (this.id) {
			this.updateAlbumData();
		}

		this.$on('retryUploadTrack', (params) => {

			if (params.isReUpload) {
				this.prepareUploadTracks[params.trackIndex].isLosslessUploaded = false;
                this.tracksUploading = false;
				return;
			}

			this.storeTrack(params.trackFile, params.trackIndex, true);
		});

        this.loadSongKeys();
	},
	mounted() {
		if (!this.id) {
			this.changeCurrentStep(1);
			this.$store.commit('SET_TEMP_CREATED_ALBUMS_ID', null);
			this.$store.commit('SET_CREATED_ALBUM', '');
		}
		const currentFragment = window.location.hash;
		if (currentFragment.includes("#step=3")) {
			this.getStripeData();
			this.changeCurrentStep(3);
		}
	},
	destroyed() {
		document.removeEventListener("visibilitychange", this.handleVisibilityChange);
        window.removeEventListener('online', this.updateOnlineStatus);
        window.removeEventListener('offline', this.updateOnlineStatus);

        this.stopTimer();
	},
	methods: {
		...mapActions([
			'GET_CREATED_ALBUM', 'GET_GENRES', 'GET_YOUR_ARTISTS', 'GET_YOUR_RECORD_LABELS',
			'ADD_MUSIC_RELEASE', 'EDIT_MUSIC_RELEASE', 'CHANGE_ALBUM_STATUS',
			'ADD_SONGS', 'EDIT_SONG', 'REMOVE_SONG', 'UPDATE_SONG',
			'ADD_ALBUM_IMAGE', 'GET_ARTIST',

			// commerce && stripe
			'POST_MUSIC_RELEASE_COMMERCE', 'GET_MUSIC_RELEASE_COMMERCE',
			'GET_STRIPE_ACCOUNT', 'GET_LINK_STRIPE_ACCOUNT', 'CREATE_STRIPE_ACCOUNT',

            'loadSongKeys',
		]),
		// Add imported validator functions to methods
		blockTitleInput,
		blockDescriptionTextarea,
		numbersOnly,
		keyHandler,
		
		// Add imported date formatter functions to methods
		formatDate,
		convertToDatePicker,
		getDay,
		
		handleVisibilityChange() {
			this.isActiveTab = !document.hidden;
			if (this.isActiveTab) {
				this.getStripeData();
			}
		},
        updateOnlineStatus() {
            this.online = navigator.onLine;
            this.isConnected = this.online;

            if (this.isConnected && this.id) {
                setTimeout(() => {
                    this.updateAlbumData();
                }, 700);
            }
        },

		showKeys(track, index) {
			this.showTrackKeysPanelIndex = index;
			this.showTrackKeysPanel = true;
			this.positionKeysPanel = (index * 70) + 70;
        },
        getKeyCode(track) {
            if (track?.key?.code) {
                return track.key.code;
            }

            return null;
        },
        isSelectedKey(showTrackKeysPanelIndex, key) {
            if (this.prepareUploadTracks[showTrackKeysPanelIndex]?.key?.id === key.id) {
                return true;
            }

            return false;
        },
		select_key(key) {
			this.prepareUploadTracks[this.showTrackKeysPanelIndex].key = key;
			this.showTrackKeysPanel = false;
		},
		onClickOutsideKeys: function (event) {
			if (event.target.id !== 'track-key') {
				this.showTrackKeysPanel = false;
			}
		},
		confirmChangeAccount() { // artist
			this.viewListAccounts = 'Artist';
			this.$store.commit('SET_SELECT_ARTIST', null);
			if (this.id) {
				this.warningChangeArtistDialog = true;
			} else {
				this.modalCreateNewAccount = true;
			}
		},
		confirmChangeLabel() { // record-label
			this.viewListAccounts = 'Label';
			this.modalCreateNewAccount = true;
		},
		setAccount() {
			this.viewListAccounts = null;
			this.modalCreateNewAccount = true;
		},
		clearInput(el) {
			this.$store.commit('SET_VARIOUS_ARTISTS', false);
			if (el === 'label') {
				this.details.label.id = '';
				this.details.label.title = '';

				this.$store.commit('SET_SELECTED_LABEL', null);
			}
			if (el === 'artist') {
				this.details.artist.id = '';
				this.details.artist.name = '';
				this.$store.commit('SET_SELECT_ARTIST', null);
			}
		},

		getGenres() {
			this.GET_GENRES()
				.then(() => {
					this.genresList = this.GENRES;
				})
				.catch(err => console.error(`getGenres, ${err}`))
				.finally(() => {
					if (this.genresList.length && this.details.genre.id) {
						let subgenreList = this.genresList.find(el => el.id === this.details.genre.id);
						this.subGenresList = subgenreList.subGenres;
					}
				})
		},

		updateAlbumData() {
			this.GET_CREATED_ALBUM(this.id)
				.then(() => {
					this.details.title = this.CREATED_ALBUM.title;
					this.details.description = !this.CREATED_ALBUM.description ? '' : this.CREATED_ALBUM.description;
					this.details.tags = !this.CREATED_ALBUM.tags ? [] : this.CREATED_ALBUM.tags;

					if (this.CREATED_ALBUM.artist) {
						this.details.artist.id = this.CREATED_ALBUM.artist.id;
						this.details.artist.name = this.CREATED_ALBUM.artist.name;
						// this.$store.commit('SET_SELECTED_ARTIST_ID', this.CREATED_ALBUM.artist.id);
					}

					if (this.CREATED_ALBUM.genre) {
						this.details.genre.id = this.CREATED_ALBUM.genre.id;
						this.details.genre.name = this.CREATED_ALBUM.genre.name;

						if (!this.genresList.length) {
							this.getGenres();
						}
					}
					if (this.CREATED_ALBUM.subGenres.length) {
						this.details.subgenre.id = this.CREATED_ALBUM.subGenres[0].id;
						this.details.subgenre.name = this.CREATED_ALBUM.subGenres[0].name;
					}

					this.prepareUploadTracks = [];
					if (this.CREATED_ALBUM.playList.length) {
						for (let track of this.CREATED_ALBUM.playList) {
							if (!track.song.deletedAt) {
                                track.song.position = track.position;
                                this.prepareUploadTracks.push(track.song);
								this.prepareUploadTracks.sort((a, b) => (a.position > b.position) ? 1 : -1);
							}
						}
					}

					this.clipperAvatar = !this.CREATED_ALBUM.bigImage ? '' : this.CREATED_ALBUM.bigImage;

                    this.createLoading = false;
                    this.tracksUploading = false;
				})
				.catch(err => {
					console.error(`none release id= ${this.id}, error: ${err}`);
					this.$router.replace({name: 'createMusicRelease'});
				})
			this.GET_MUSIC_RELEASE_COMMERCE({id: this.id})
				.then(() => {
					this.publishFree = this.MUSIC_RELEASE_COMMERCE.is_free === true;
					this.albumPrice = this.MUSIC_RELEASE_COMMERCE.price !== null ? this.MUSIC_RELEASE_COMMERCE.price.toFixed(2) : null;

					if (this.MUSIC_RELEASE_COMMERCE.songs.length) {
						for (let song of this.MUSIC_RELEASE_COMMERCE.songs) {
							if (song.price !== null) {
								song.price = song.price.toFixed(2);
								this.allowTracksChecked = true;
							}
						}
					}
				})
				.catch(err => console.error(`GET_MUSIC_RELEASE_COMMERCE, ${err}`));
		},
		changeTrackPosition: function (tracks, $event) {
			const newPosition = $event.moved.newIndex;
			const updateTrackPosition = tracks[newPosition];
			updateTrackPosition.position = newPosition + 1;
			for (let track of tracks) {
				this.releaseInfoChanged = true;
				track.position = tracks.indexOf(track) + 1;
			}

            this.prepareUploadTracks = tracks;
		},
		canMoveTrack(evt) {
			if (this.CREATED_ALBUM.beenPublished) {
				return false;
			}
			return true;
		},
		renameTrack() {
			// this.warningRemoveTrackDialog = true;
		},

		selectTrackFile() {
            if (this.WARNING_CHANGE_STATUS === true) {
				this.$refs.uploadForm.click();
			}
			if (this.CREATED_ALBUM.beenPublished) {
				this.$store.commit('SET_WARNING_CHANGE_STATUS', false);
				this.warningChangeAlbumDialog = true;
			} else {
				this.$refs.uploadForm.click();
			}
		},
		handleTracksUpload(event) {
			const maxLengthTracks = 50;

            if (event.target.files.length > maxLengthTracks) {
				this.uploadLimitDialog = true;
				return;
			}

			for (let track = 0; track < event.target.files.length; track++) {
				let uploadedTracksLength = this.prepareUploadTracks.length;

				if ((this.prepareUploadTracks.length + 1) > maxLengthTracks) {
					this.uploadLimitDialog = true;
					return;
				}

				let fileSize = event.target.files[track].size / 1048576;
				let trackName = event.target.files[track].name;

				let hasTrack = this.prepareUploadTracks.some(el => el.name === trackName || el.trackFileName === trackName);

                if (!hasTrack) {
					if (fileSize < 200) {
						this.releaseInfoChanged = true;
						this.prepareUploadTracks.push({
							'title': trackName.split(/\.(?=[^\.]+$)/)[0],
							'name': trackName,
							'key': event.target.files[track].key,
							'position': uploadedTracksLength,
							'progress': 0,
							'file': event.target.files[track],
							'success': false
						});
					} else {
						this.trackLimitDialog = true;
					}
				} else {
                    // This rule applies when we attempt to reload a track in DigitalOcean Spaces.
                    let trackIndex = this.prepareUploadTracks.findIndex(el => el.name === trackName || el.trackFileName === trackName);
                    if (trackIndex !== -1 && !this.prepareUploadTracks[trackIndex].isLosslessUploaded && fileSize < 200) {
                        this.prepareUploadTracks[trackIndex].file = event.target.files[track];
                        this.prepareUploadTracks[trackIndex].isLosslessUploaded = undefined;
                        this.prepareUploadTracks[trackIndex].success = false;
                        this.prepareUploadTracks[trackIndex].progress = 0;
                    }
                }
			}
            this.$refs.uploadForm.value = '';
		},
		confirmDeleteTrack(index) {
			// this.allowRemoveTracksIndex = index;
			// this.warningRemoveTrackDialog = true;

			this.deleteTrack(index);
		},

        trackDeleteProgress(trackId) {
            if (trackId === undefined) {
                return false;
            }

            return this.trackDeleteProgressId === trackId;
        },

		deleteTrack(index) {
            let track = this.prepareUploadTracks[index];
            if (track && track.id) {
                this.trackDeleteProgressId = track.id;

                this.REMOVE_SONG(track.id)
                    .then(() => {
                        this.prepareUploadTracks.splice(index, 1);
                        this.releaseInfoChanged = true;
                        this.$refs.uploadForm.value = '';
                    })
                    .catch(err => console.error(`deleteTrack, ${err}`))
                    .finally(() => this.trackDeleteProgressId = undefined)

                return;
            }

            this.prepareUploadTracks.splice(index, 1);
            this.releaseInfoChanged = true;
            this.$refs.uploadForm.value = '';
			// this.warningRemoveTrackDialog = false;
		},


		// upload cover img album
		changeAva() { // click button "Select"
			// Just reset the error state when changing the image
			this.photoLimitDialog = false;
			this.errorMessage = '';
			
			// Note: The actual file selection is handled by the CoverImageUploader component
			// The photoUpload method will be called when the file input's change event fires
		},

		clipImage() {
			let uploadedType = this.uploadedFile.type.split('/')[1];
			
			// Get clipper from CoverImageUploader component
			const clipperComponent = this.$refs.coverImageUploader;
			const clipper = clipperComponent ? clipperComponent.getClipper() : null;
			
			if (!clipper) {
				console.error('Clipper not found');
				return;
			}
			
			try {
				console.log('Current scale:', this.scale);
				
				// Make sure the clipper has the correct scale
				if (clipper.bgWH$) {
					console.log('Clipper current zoom:', clipper.bgWH$);
				}
				
				// Use the clip method with the current scale value
				const canvas = clipper.clip({
					maxWPixel: 1000
				}).toDataURL(`image/${uploadedType}`, 0.9); // canvas->image (base64)
				
				if (!canvas) {
					console.error('Failed to create canvas');
					return;
				}
				
				this.uploadedFile = baseUrlToBlob(canvas);
				this.clipperDialog = false;

				const fileReader = new FileReader();
				fileReader.addEventListener('load', () => {
					this.clipperAvatar = fileReader.result;
					console.log('Updated clipperAvatar with zoomed/clipped image');
				});
				
				// This line was missing - actually read the file to trigger the load event
				fileReader.readAsDataURL(this.uploadedFile);
			} catch (error) {
				console.error('Error clipping image:', error);
				this.errorMessage = 'Failed to clip image. Please try again.';
				setTimeout(() => {
					this.errorMessage = '';
				}, 3000);
			}
		},
		photoUpload(event) {
			// If event is passed from component, get the file from the event
			if (event && event.target) {
				this.uploadedFile = event.target.files[0];
			} else {
				// Fallback to direct access if needed
				this.uploadedFile = this.$refs.uploadedFile ? this.$refs.uploadedFile.files[0] : null;
			}

			if (!this.uploadedFile) {
				console.log('No file selected');
				return;
			}

			// pre load image url
			const fileReader = new FileReader();
			fileReader.readAsDataURL(this.uploadedFile);
			fileReader.addEventListener('load', () => {
				let image = new Image();
				image.src = fileReader.result;

				image.addEventListener('load', () => {
					let imageSize = this.uploadedFile.size / 1048576; //MB

					this.uploadImageLoader = true;

					if (imageSize <= 10) {
						// The child component will emit update-dimensions when clipperAvatar changes
						// so we don't need to manually set dimensions here
						this.clipperAvatar = fileReader.result;
						this.scale = 0.5;

						// Let the component handle most of the logic
						this.clipperDialogVisible = true;
						this.clipperDialog = true;
						
						// Allow some time for child component to emit dimensions
						setTimeout(() => {
							this.uploadImageLoader = false;
							
							// Now check if the dimensions are valid
							if (this.scaleImageWidth > 1000 && this.scaleImageHeight > 1000) {
								this.validImage = true;
								this.releaseInfoChanged = true;
							} else {
								this.validImage = false;
								if (this.$refs.uploadedFile) {
									this.$refs.uploadedFile.value = null;
								}
								
								// Alert the user if dimensions are insufficient
								this.errorMessage = 'Image dimensions must be at least 1000x1000 pixels.';
								setTimeout(() => {
									this.errorMessage = '';
								}, 3000);
							}
						}, 600);
					} else {
						this.photoLimitDialog = true;
						this.validImage = false;
						this.uploadImageLoader = false;
						this.clipperDialog = false;
						this.clipperAvatar = !this.CREATED_ALBUM.bigImage ? '' : this.CREATED_ALBUM.bigImage;
						if (this.$refs.uploadedFile) {
							this.$refs.uploadedFile.value = null;
						}
					}
				});
			});
		},
		imgAvaLoad() {
			// This method is now handled by the CoverImageUploader component
			// which emits the update-dimensions event that we handle in updateDimensions
			// We keep this method for backward compatibility
			const clipperComponent = this.$refs.coverImageUploader;
			const clipper = clipperComponent ? clipperComponent.getClipper() : null;
			
			if (clipper) {
				clipper.setWH$.next(0.5);
				this.scale = 0.5;
				let loadImage = clipper.clip();
				this.scaleImageWidth = loadImage.width;
				this.scaleImageHeight = loadImage.height;
			}
		},
		handleZoom(scale) {
			console.log('Parent handleZoom called with scale:', scale);
			// Validate scale is a number
			if (typeof scale !== 'number') {
				console.warn('Invalid scale value received:', scale);
				return;
			}
			
			// Ensure scale is within bounds
			if (scale < 0.5) scale = 0.5;
			if (scale > 2) scale = 2;
			
			this.scale = scale;
		},
		updateDimensions({ width, height }) {
			// Validate dimensions to avoid 0x0 issue
			if (!width || !height || width <= 0 || height <= 0) {
				console.warn('Invalid dimensions received:', width, 'x', height);
				return;
			}
			
			console.log('Updating dimensions to:', width, 'x', height);
			this.scaleImageWidth = width;
			this.scaleImageHeight = height;
		},
		closeClipperDialog() {
			this.clipperDialog = false;
			this.$refs.uploadedFile.value = null;
			if (this.CREATED_ALBUM.bigImage) {
				this.clipperAvatar = this.CREATED_ALBUM.bigImage;
			} else {
				this.clipperAvatar = '';
			}
		},


		// Album Details
		setGenre(genre) {
			this.details.genre.id = genre.id;
			this.details.genre.name = genre.name;
			this.details.subgenre.id = '';
			this.details.subgenre.name = '';
		},
		setSubgenre(subgenre) {
			this.details.subgenre.id = subgenre.id;
			this.details.subgenre.name = subgenre.name;
		},
		addTag(evt) {
			evt = (evt) ? evt : window.event;
			let charCode = (evt.which) ? evt.which : evt.keyCode;

			this.newTag.trim().normalize('NFC');
			// Enter || Space || Comma || Tab
			if (this.newTag.length && (charCode === 13 || charCode === 32 || charCode === 44 || charCode === 9)) {
				evt.preventDefault();
				this.details.tags.push(this.newTag);
				this.releaseInfoChanged = true;
				this.newTag = '';
			}
		},
		removeTag(ev) {
			if (ev.key === 'Backspace') {
				this.details.tags.splice(-1, 1);
				this.releaseInfoChanged = true;
			}
		},


		changeCurrentStep(step) {
			if (this.CURRENT_STEP === 3 && step === 2) {
				this.saveCommerce('save', 'dynamic');
			}
			this.$store.commit('SET_CURRENT_STEP', step);
			this.createLoading = false;
		},
		changeAlbumStatus(status) {
			let params = {
				id: this.id,
				status: status,
			}
			this.CHANGE_ALBUM_STATUS(params)
				.then(() => {
					setTimeout(() => {
						// const albumTitle = this.CREATED_ALBUM.title.trim().replace(/ /g, '-');
						this.$router.push({name: 'albumDetails', params: {id: this.id, name: window.toHumanFormat(this.CREATED_ALBUM.identifier)}});
					}, 600);
				})
				.catch(err => console.error(`changeAlbumStatus, ${err}`))
		},


		async storeTrack(track, index, isReUpload) {
			this.tracksUploading = true;

			let instance = axios.create();
			delete instance.defaults.headers.common['Authorization'];

            let trackUrl = this.PREPARE_TRACK.url ?? this.prepareUploadTracks[index].preSignedUploadUrl;

            instance.put(trackUrl, track, {
				onUploadProgress: (progressEvent) => {
					this.prepareUploadTracks[index].progress = parseInt(Math.round(progressEvent.loaded * 100 / progressEvent.total));
                    this.$set(this.prepareUploadTracks, index, this.prepareUploadTracks[index])
                }
			})
				.then(() => {
					this.prepareUploadTracks[index].success = true;

					// After successfully uploading a track to DigitalOcean, we need to notify the backend and set the isLosslessUploaded flag
                    let songId = this.prepareUploadTracks[index].id;
                    if (songId) {
						this.EDIT_SONG({
							id: songId,
							isLosslessUploaded: true
						}).then(() => {
							this.prepareUploadTracks[index].isLosslessUploaded = true;
						})
					}
				})
				.catch(err => {
					this.prepareUploadTracks[index].success = false;

					this.$emit('retryUploadTrack', {
						'trackFile': track,
						'trackIndex': index,
						'isReUpload': isReUpload ?? false
					});

                    if (err.toString().includes('Network Error') && isReUpload) {
                        this.isConnected = false;
                    }
				})
				.finally(() => {
					let tracks = this.prepareUploadTracks.filter(track => track.success === false);
					if (!tracks.length) {
						this.tracksUploading = false;
						if (this.tracksUploading === false && this.tracksEdited === false && this.tracksDeleted === false && this.changeAvatarLoader === false) {
							this.createLoading = false;

							this.updateAlbumData();
							this.changeCurrentStep(3);
						}

					}
				})
		},
		async storeCoverImage(imageFormData) {
			console.log('imageFormData', imageFormData);
			this.changeAvatarLoader = true;
			await this.ADD_ALBUM_IMAGE(imageFormData)
				.then(() => {
					console.log('then storeCoverImage - imageFormData', imageFormData);
				})
				.catch(err => {
					console.error(`storeCoverImage, ${err}`);
				})
				.finally(() => {
					this.changeAvatarLoader = false;

					if (this.ALBUM_IMAGE_STATUS && this.ALBUM_IMAGE_STATUS === 'success') {
						if (this.tracksUploading === false && this.tracksEdited === false && this.tracksDeleted === false && this.changeAvatarLoader === false) {
							this.changeCurrentStep(3);
						}
					} else {
						this.createLoading = false;
						this.validImage = false;
					}
				})
		},


		saveReleaseInfo(save) {
			console.log('saveReleaseInfo', save);
			if (save === 'continue') {
				this.$v.details.title.$touch();
				this.$v.details.genre.id.$touch();
				this.$v.details.subgenre.id.$touch();
				this.$v.datePicker.$touch();
				if (!this.VARIOUS_ARTISTS) {
					this.$v.details.artist.name.$touch();
				}

			}
			this.$v.details.title.$touch();

			if ((save === 'continue' &&
					this.$v.details.title.$error === false &&
					this.$v.details.genre.id.$error === false &&
					this.$v.details.subgenre.id.$error === false &&
					this.$v.datePicker.$error === false &&
					this.$v.details.artist.name.$error === false) || (save === 'save' && this.$v.details.title.$error === false) || save === 'publish' && this.$v.details.title.$error === false) {

				let artistID = this.details.artist.id;
				let selectedArtist = this.YOUR_ARTISTS.find(el => el.id === artistID);

				if (!this.VARIOUS_ARTISTS) {
					if (
						!selectedArtist || !selectedArtist.hasActiveSubscription || (!selectedArtist.hasActiveSubscription && !selectedArtist.subscription)
						|| (!selectedArtist.hasActiveSubscription && selectedArtist.subscription && !selectedArtist.subscription.canActivate)
					) {
						this.artistSubscriptionError = true;
						return;
					}
				}


				if (!this.releaseInfoChanged) {
					this.createLoading = true;
					setTimeout(() => {
						this.changeCurrentStep(2);
					}, 600);
					return;
				}

				const albumDetails = {
					title: this.details.title.normalize('NFC'),
					artist: !this.details.artist.id ? null : `artists/${this.details.artist.id}`,
					recordLabel: !this.details.label.id ? null : `record_labels/${this.details.label.id}`,
					description: !this.details.description ? null : this.details.description.normalize('NFC'),
					type: 'album',
					tags: !this.details.tags ? null : this.details.tags,
					genre: !this.details.genre.id ? null : `genres/${this.details.genre.id}`,
					subGenres: !this.details.subgenre.id ? [] : [`sub_genres/${this.details.subgenre.id}`],
					createdAt: new Date(this.datePicker).toISOString(),
					releasedAt: new Date(this.datePicker).toISOString(),
				};

				this.createLoading = true;


				if (!this.id) {
					this.ADD_MUSIC_RELEASE(albumDetails)
						.then(() => {
							if (this.TEMP_CREATED_ALBUMS_ID) {
								this.$router.replace({name: 'createMusicRelease', params: {id: this.TEMP_CREATED_ALBUMS_ID}});
								this.changeCurrentStep(2);
								// localStorage.removeItem('tempReleaseData');
							}
						})
						.finally(() => {
							this.createLoading = false;
						})
				} else {
					albumDetails.id = +this.id;
					this.EDIT_MUSIC_RELEASE(albumDetails)
						.then(() => {
							if (this.TEMP_CREATED_ALBUMS_ID) {
								// setTimeout(() => {
								this.clipperAvatar = !this.CREATED_ALBUM.bigImage ? '' : this.CREATED_ALBUM.bigImage;
								this.changeCurrentStep(2);
								// localStorage.removeItem('tempReleaseData');
								// }, 600);
							}
						})
						.finally(() => {
							this.createLoading = false;
						});
				}
			}
		},
		saveFiles(save) {
            setTimeout(() => {
                let losslessUploadedFailed = this.prepareUploadTracks.filter(track => track.isLosslessUploaded === false);
                if (losslessUploadedFailed.length) {
                    this.losslessUploadedFailedDialog = true;
                    return;
                }

                if (save === 'continue') {
                    this.$v.prepareUploadTracks.$touch();
                    this.$v.clipperAvatar.$touch();
                }

                if (this.id) {
                    if ((save === 'continue' && this.$v.prepareUploadTracks.$error === false && this.$v.clipperAvatar.$error === false) || save === 'save' || save === 'publish') {
                        this.createLoading = true;

                        if (this.prepareUploadTracks.length) {
                            for (let track = 0; track < this.prepareUploadTracks.length; track++) {

                                // ADD SONGS
                                if (!this.prepareUploadTracks[track].id) {
                                    this.tracksUploading = true;

                                    let song = {
                                        id: +this.id,
                                        trackName: this.prepareUploadTracks[track].title.normalize('NFC'),
                                        trackFileName: this.prepareUploadTracks[track].name.normalize('NFC'),
                                        trackFileExtension: this.prepareUploadTracks[track].name.split(/\.(?=[^\.]+$)/)[1],
                                        bpm: !this.prepareUploadTracks[track].bpm ? 0 : Number(this.prepareUploadTracks[track].bpm),
                                        key: !this.prepareUploadTracks[track].key ? null : this.prepareUploadTracks[track].key.id,
                                        style: !this.subgenre ? [] : [this.subgenre],
                                        position: this.prepareUploadTracks[track].position,
                                    }

                                    this.ADD_SONGS(song)
                                        .then(() => {
                                            this.prepareUploadTracks[track].id = this.PREPARE_TRACK.id;
                                            this.storeTrack(this.prepareUploadTracks[track].file, track);
                                        })
                                        .catch(err => {
                                            console.error(`ADD_SONGS, ${err}`);
                                        })
                                }

                                // EDIT SONGS
                                if (this.prepareUploadTracks[track].id) {
                                    this.tracksEdited = true;
                                    this.prepareUploadTracks[track].updateSuccess = false;
                                    let song = {
                                        id: this.prepareUploadTracks[track].id,
                                        releaseId: this.id,
                                        title: this.prepareUploadTracks[track].title.normalize('NFC'),
                                        bpm: !this.prepareUploadTracks[track].bpm ? 0 : Number(this.prepareUploadTracks[track].bpm),
                                        key: !this.prepareUploadTracks[track].key ? null : this.prepareUploadTracks[track].key.id,
                                        position: this.prepareUploadTracks[track].position,
                                    }

                                    this.UPDATE_SONG(song)
                                        .then(() => {
                                            if (undefined === this.prepareUploadTracks[track].isLosslessUploaded && this.prepareUploadTracks[track].file) {
                                                this.storeTrack(this.prepareUploadTracks[track].file, track);
                                            }

                                            this.prepareUploadTracks[track].updateSuccess = true;
                                        })
                                        .catch(err => {
                                            console.error(`EDIT_SONG, ${err}`);
                                        })
                                        .finally(() => {
                                            let updateSuccess = this.prepareUploadTracks.filter(track => track.updateSuccess === false);
                                            let isLosslessUploaded = this.prepareUploadTracks.filter(track => track.isLosslessUploaded !== true);

                                            if (!updateSuccess.length && !isLosslessUploaded.length) {
                                                this.tracksEdited = false;
                                                if (this.tracksUploading === false && this.tracksEdited === false && this.tracksDeleted === false && this.changeAvatarLoader === false) {
                                                    this.updateAlbumData();
                                                    this.changeCurrentStep(3);
                                                }
                                            } else if (!updateSuccess.length && isLosslessUploaded.length) {
                                                this.tracksEdited = false;
                                                let isLosslessUploadedIndex = this.prepareUploadTracks.findIndex(el => el.isLosslessUploaded !== true);
                                                if (isLosslessUploadedIndex !== -1 && !this.prepareUploadTracks[isLosslessUploadedIndex].file) {
                                                    this.createLoading = false;
                                                }
                                            }
                                        })
                                }
                            }

                            //@todo-vp: I have updated the logic of the deleteTrack method. If the current task is closed, this code should be removed.
                            // DELETE SONGS
                            // if (this.CREATED_ALBUM.playList.length) {
                            //     let deleteTrack = this.CREATED_ALBUM.playList.filter(n => !this.prepareUploadTracks.some(m => m.id === n.song.id));
                            //     if (deleteTrack.length) {
                            //         this.tracksDeleted = true;
                            //         for (let item = 0; item < deleteTrack.length; item++) {
                            //             let track = deleteTrack[item].song.id;
                            //             deleteTrack[item].deleteSuccess = false;
                            //             this.REMOVE_SONG(track)
                            //                 .then(() => {
                            //                     deleteTrack[item].deleteSuccess = true;
                            //                 })
                            //                 .catch(err => console.error(`deleteTrack, ${err}`))
                            //                 .finally(() => {
                            //                     let tracksDelete = deleteTrack.filter(track => track.deleteSuccess === false);
                            //                     if (!tracksDelete.length) {
                            //                         this.tracksDeleted = false;
                            //                         if (this.tracksUploading === false && this.tracksEdited === false && this.tracksDeleted === false && this.changeAvatarLoader === false) {
                            //                             this.updateAlbumData();
                            //                             this.changeCurrentStep(3);
                            //                         }
                            //                     }
                            //                 })
                            //         }
                            //     }
                            // }

                        }

                        // cover image
                        if (this.uploadedFile) {
                            let imageFormData = new FormData();
                            imageFormData.append('file', this.uploadedFile);
                            imageFormData.id = this.id;

                            this.changeAvatarLoader = true;
                            this.ADD_ALBUM_IMAGE(imageFormData)
                                .catch(err => {
                                    console.error(`storeCoverImage, ${err}`);
                                })
                                .finally(() => {
                                    this.changeAvatarLoader = false;

                                    if (this.ALBUM_IMAGE_STATUS && this.ALBUM_IMAGE_STATUS === 'success') {
                                        if (this.tracksUploading === false && this.tracksEdited === false && this.tracksDeleted === false && this.changeAvatarLoader === false) {
                                            this.changeCurrentStep(3);
                                        }
                                    } else {
                                        this.createLoading = false;
                                        this.validImage = false;
                                    }
                                })
                            // this.storeCoverImage(imageFormData);
                        }
                    }
                } else {
                    this.changeCurrentStep(1);
                }
            }, 500);
		},


		// commerce stripe
		getStripeData() {
			this.GET_STRIPE_ACCOUNT()
				.then(() => {
					if (this.stripeStatus !== 'no-account') {
            let params = {
              callback_success_url: `${window.location.protocol}//${window.location.host}/create-music-release/${this.id}#step=1`,
            }
            this.GET_LINK_STRIPE_ACCOUNT(params)
							.catch(err => {
								console.error(`getStripeData, ${err}`);
							})
					}
				})
				.catch(err => {
					console.error(`getStripeAccount, ${err}`);
				})
		},
		createStripe() {
			let params = {
				callback_success_url: `${window.location.protocol}//${window.location.host}/create-music-release/${this.id}#step=3`,
			}
			this.CREATE_STRIPE_ACCOUNT(params)
				.then(() => {
          window.location.href = this.CREATE_LINK_STRIPE_ACCOUNT;
					// window.open(this.CREATE_LINK_STRIPE_ACCOUNT, '_blank');
				})
				.catch(err => {
					console.error(`createStripe,npm ${err}`);
				})
				.finally(() => {
					this.getStripeData();
				})
		},
		resolveStripeIssue() {
			let params = {
				callback_success_url: `${window.location.protocol}//${window.location.host}/create-music-release/${this.id}#step=3`,
			}
			this.GET_LINK_STRIPE_ACCOUNT(params)
				.then(() => {
					window.location.href = this.STRIPE_ACCOUNT_LINK;
				})
				.catch(err => {
					console.error(`getLinkStripeAccount, ${err}`);
				})
		},
		visitStripe() {
			window.location.href = this.STRIPE_ACCOUNT_LINK;
		},

		onBlurTrackPrice(value, index) {
			if (value.price !== null) {
				let newValue = String(value.price);
				if (newValue.length !== 0) {
					if (newValue.indexOf(".") === -1) {
						newValue = newValue + ".00";
					} else if (newValue.length - newValue.indexOf(".") - 1 < 2) {
						newValue = newValue + "0".repeat(2 - (newValue.length - newValue.indexOf(".") - 1));
					}
					this.MUSIC_RELEASE_COMMERCE.songs[index].price = parseFloat(newValue).toFixed(2);
				}
			}
		},
		albumPriceLimit(event) {
			const inputChar = event.key;
			if (inputChar !== '.' && isNaN(inputChar)) {
				event.preventDefault();
			} else {
				if (this.albumPrice > 1000) {
					this.albumPriceLimitDialog = true;
				}
			}
		},
		trackPriceLimit(event, index) {
			const inputChar = event.key;
			if (inputChar !== '.' && isNaN(inputChar)) {
				event.preventDefault();
			} else {
				if (this.MUSIC_RELEASE_COMMERCE.songs[index].price > 100) {
					this.trackPriceLimitDialog = true;
				}
			}
		},
		setToZero() {
			this.albumPrice = '0.00';
			this.distributionAlbumPrice('zero');
		},
		distributionAlbumPrice(variantDistribution) {
			let songs = this.MUSIC_RELEASE_COMMERCE.songs;
			let songsLength = songs.length;
			for (let i = 0; i < songsLength; i++) {
				let price = this.albumPrice / songsLength;

				switch (variantDistribution) {
					// case 'floor':
					// 	songs[i].price = Math.floor(price).toFixed(2);
					// 	break;
					case 'ceil':
						songs[i].price = Math.ceil(price).toFixed(2);
						break;
					case 'zero':
						songs[i].price = '0.00';
						break;
					case null:
						songs[i].price = null;
						break;
				}
			}
			if (this.priceDistributionDialog) {
				this.priceDistributionDialog = false;
			}
			if (this.userDecidesPriceDialog) {
				this.userDecidesPriceDialog = false;
			}
		},
		changeAllowTracksChecked() {
			if (this.CURRENT_STEP === 3 && this.allowTracksChecked === true && this.albumPrice > 0) {
				this.priceDistributionDialog = true;
			}
		},
		async saveCommerce(save, afterSave) {
			this.validCommence = true;

			// check publish free
			if (this.publishFree === true) {
				this.albumPrice = null;
				await this.distributionAlbumPrice('null');
			}

			if (this.allowTracksChecked === false) {
				await this.distributionAlbumPrice('null');
			}

			// show userDecidesPriceDialog
			if (save === 'continue' && this.publishFree === false && !this.albumPrice) {
				this.userDecidesPriceDialog = true;
				this.validCommence = false;
				return;
			}

			// check empty tracks price
			let checkEmptyTracksPrice = this.MUSIC_RELEASE_COMMERCE.songs.find(track => (!track.price || false));
			if (save === 'continue' && this.publishFree === false && (+this.albumPrice === 0 || +this.albumPrice > 0) && (this.allowTracksChecked && checkEmptyTracksPrice)) {
				this.priceDistributionDialog = true;
				this.validCommence = false;
				return;
			}


			// check max price
			let checkValidTracksPrice = this.MUSIC_RELEASE_COMMERCE.songs.find(track => track.price > 100);
			if (this.publishFree !== true && (!this.albumPrice || this.albumPrice > 1000 || (this.allowTracksChecked && checkValidTracksPrice))) {
				this.validCommence = false;
				return;
			}


			if (this.validCommence === true) {
				this.createLoading = true;

				let params = {
					id: this.id,
					is_free: this.publishFree,
					price: this.publishFree !== true ? +this.albumPrice : null
				}
				if (this.MUSIC_RELEASE_COMMERCE.songs.length) {
					let songs = [];
					for (let track of this.MUSIC_RELEASE_COMMERCE.songs) {

						let tracksPrice;
						if (this.publishFree || this.allowTracksChecked === false) {
							tracksPrice = null;
						}
						if (this.allowTracksChecked === true && !this.publishFree) {
							tracksPrice = +track.price;
						}
						// if (this.allowTracksChecked === false && !this.publishFree) {
						// 	tracksPrice = 0;
						// }

						songs.push({
							id: track.id,
							price: tracksPrice
						});
					}
					params.songs = songs;
				}

				this.POST_MUSIC_RELEASE_COMMERCE(params)
					.then(() => {
						if (save === 'continue') {
							setTimeout(() => {
								// console.log('this.CREATED_ALBUM', this.CREATED_ALBUM);
								// const albumTitle = this.CREATED_ALBUM.title.trim().replace(/ /g, '-');
								this.$router.push({name: 'albumDetails', params: {id: this.id, name: window.toHumanFormat(this.CREATED_ALBUM.identifier)}});
								this.createLoading = false;
							}, 600);
						}
						if (afterSave === 'dynamic') {
							this.createLoading = false;
						}
						if (save === 'publish' && afterSave === 'default') {
							this.changeAlbumStatus('pending');
						}
					})
					.catch(err => console.error(`POST_MUSIC_RELEASE_COMMERCE, ${err}`))
			}
		},
        startTimer() {
            this.showTimer = true;
            this.secondsRemaining = 15;
            this.intervalId = setInterval(() => {
                this.secondsRemaining > 0 ? this.secondsRemaining-- : this.stopTimer();
            }, 1000);
        },
        stopTimer() {
            clearInterval(this.intervalId);

            this.checkConnection((status) => {
                this.isConnected = status;

                if (!this.isConnected) {
                    this.startTimer();
                }
            })
        },

        checkConnection(callback) {
            let xhr = new (window.ActiveXObject || XMLHttpRequest)("Microsoft.XMLHTTP");

            xhr.onreadystatechange = function() {
                if (xhr.readyState == 4) {
                    if ((xhr.status >= 200 && xhr.status < 300) || xhr.status == 304) {
                        callback(true);
                    } else {
                        callback(false);
                    }
                }
            };

            try {
                xhr.open("HEAD", process.env.VUE_APP_API_URL, true);
                xhr.send();
            } catch (error) {
                callback(false);
            }
        },
	}
}
</script>

<style lang="scss">
.interrupted {
    padding: 0!important;
}
.upload_status {
    .delete_track {
        &.delete-process {
            background-image: none!important;
        }
        .v-progress-circular {
            left: 16px!important;
            width: 22px!important;
            height: 22px!important;
        }
    }
}

.error-message {
    color: #ff0000;
    font-size: 14px;
    margin-top: 10px;
    padding: 8px;
    background-color: rgba(255, 0, 0, 0.1);
    border-radius: 4px;
    text-align: center;
}
</style>
