/**
 * Date formatting utility functions
 */

/**
 * Converts a date from YYYY-MM-DD format to MM/DD/YYYY format
 * @param {string} date - Date in YYYY-MM-DD format
 * @returns {string|null} - Date in MM/DD/YYYY format or null if no date provided
 */
export const formatDate = (date) => {
  if (!date) return null;

  const [year, month, day] = date.split('-');
  return `${month}/${day}/${year}`;
};

/**
 * Converts a date from MM/DD/YYYY format to MM-DD-YYYY format for date picker
 * @param {string} date - Date in MM/DD/YYYY format
 * @returns {string|null} - Date in MM-DD-YYYY format or null if no date provided
 */
export const convertToDatePicker = (date) => {
  if (!date) return null;

  const [month, day, year] = date.split('/');
  return `${month}-${day}-${year}`;
};

/**
 * Gets the day of the week from a date
 * @param {string} date - Date string
 * @returns {string} - Day of the week
 */
export const getDay = (date) => {
  const daysOfWeek = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];
  const i = new Date(date).getDay();
  return daysOfWeek[i];
}; 