<template>
    <div class="body">
      <img :src="albumImage" alt="">
      <div class="image-wrapper">
        <input type="file" hidden ref="uploadedFile" accept="image/png, image/jpeg" @change="onPhotoUpload">
        <button class="close_modal" @click="onCloseDialog"></button>
  
        <v-dialog :content-class="'photo_clipper_dialog'" v-model="showDialog" width="500" :persistent="false" :retain-focus="false">
          <div class="progress_loader" v-if="uploadImageLoader">
            <v-progress-circular :size="100" :width="3" color="#20C4F5" indeterminate></v-progress-circular>
          </div>
  
          <div class="photo_clipper">
            <button class="dialog-close-btn" @click="onCloseDialog" aria-label="Close dialog">×</button>
            
            <clipper-fixed
              preview="avatarPreview"
              ref="clipper"
              :ratio="1"
              class="basic lg clipper-fixed"
              :src="clipperAvatar"
              bg-color="transparent"
              :outputSize="null"
              :fixed="true"
              :round="false"
              :grid="false"
              :min-scale="0.5"
              :initial-scale="localScale"
              :zoomable="true"
              shadow="rgba(0,0,0,0.8)"
              :area="50"
              :handle-zoom-event="onHandleZoom"
              @load="onImgAvaLoad"
              :rotate="0">
              <div slot="placeholder">No image</div>
            </clipper-fixed>
  
            <div class="upload_image_size" :class="{'err': scaleImageWidth < 1000 || scaleImageHeight < 1000 }">
              {{ scaleImageWidth }} x {{ scaleImageHeight }}
            </div>
            
            <!-- Error explanation text positioned directly below resolution numbers -->
            <div v-if="!validImage && !uploadImageLoader" class="resolution_error_text">
              Image does not fit criteria, please choose a JPG or PNG photo at least 1,000 pixels in width and length as
              well as less than 10 mb in size.
            </div>
            
            <clipper-range v-model="localScale" :min="0.5" :max="2"></clipper-range>
  
            <div class="actions_clipper_photo">
              <button type="button" class="primary_btn small" @click="onChangeImage()" v-ripple>Upload New Photo</button>
              <button 
                type="button" 
                class="clip_photo primary_btn small"
                v-if="scaleImageWidth >= 1000 && scaleImageHeight >= 1000" 
                @click="onClipImage" 
                v-ripple>
                Save
              </button>
              <button v-else type="button" class="clip_photo primary_btn small disabled">Save</button>
            </div>
          </div>
        </v-dialog>
  
        <div class="select_cover_album" :class="{'default_img': !clipperAvatar}" @click="onChangeImage">
          <img class="cover_select_img" v-if="clipperAvatar && validImage" :src="clipperAvatar" alt="">
          <span class="select_img">Select</span>
        </div>
  
        <span class="description_">10 MB limit<br> 1000 x 1000 pixels or larger<br> JPG, PNG
          <br>
          <span class="error-msg cover_img" v-if="validationError || !validImage">*Required.</span>
        </span>
      </div>
    </div>
  </template>
  
  <script>
  export default {
    name: 'CoverImageUploader',
    props: {
      albumImage: {
        type: String,
        default: ''
      },
      validationError: {
        type: Boolean,
        default: false
      },
      clipperAvatar: {
        type: String,
        default: ''
      },
      validImage: {
        type: Boolean,
        default: true
      },
      scaleImageWidth: {
        type: Number,
        default: null
      },
      scaleImageHeight: {
        type: Number,
        default: null
      },
      uploadImageLoader: {
        type: Boolean,
        default: false
      },
      clipperDialog: {
        type: Boolean,
        default: false
      },
      scale: {
        type: Number,
        default: 0.5
      }
    },
    computed: {
      showDialog: {
        get() {
          return this.clipperDialog;
        },
        set(value) {
          this.$emit('update:clipper-dialog', value);
        }
      },
      localScale: {
        get() {
          return this.scale;
        },
        set(value) {
          this.$emit('handle-zoom', value);
        }
      }
    },
    methods: {
      detectAndUpdateDimensions() {
        const img = new Image();
        img.onload = () => {
          if (img.naturalWidth > 0 && img.naturalHeight > 0) {
            this.$emit('update-dimensions', {
              width: img.naturalWidth,
              height: img.naturalHeight
            });
          }
          URL.revokeObjectURL(img.src);
        };
        
        if (this.clipperAvatar) {
          img.src = this.clipperAvatar;

          setTimeout(() => {
            if (this.$refs.clipper) {
              const clipperImg = this.$refs.clipper.$el.querySelector('img');
              if (clipperImg && clipperImg.naturalWidth > 0 && clipperImg.naturalHeight > 0) {
                this.$emit('update-dimensions', {
                  width: clipperImg.naturalWidth,
                  height: clipperImg.naturalHeight
                });
              }
            }
          }, 300);
        }
      },
      onPhotoUpload(event) {
        const file = event.target.files[0];
        if (file) {
          const img = new Image();
          img.onload = () => {
            if (img.naturalWidth > 0 && img.naturalHeight > 0) {
              this.$emit('update-dimensions', {
                width: img.naturalWidth,
                height: img.naturalHeight
              });
            }
            URL.revokeObjectURL(img.src);
          };
          img.src = URL.createObjectURL(file);
        }
        this.$emit('photo-upload', event);
      },
      onCloseDialog() {
        this.$emit('close-dialog');
      },
      onChangeImage() {
        if (this.$refs.uploadedFile) {
          this.$refs.uploadedFile.click();
        } else {
          console.error('File input reference not found in CoverImageUploader');
        }
        
        this.$emit('change-image');
      },
      onImgAvaLoad() {
        if (this.$refs.clipper) {
          this.$refs.clipper.setWH$.next(this.scale || 0.5);
          
          setTimeout(() => {
            const clipperImg = this.$refs.clipper.$el.querySelector('img');
            if (clipperImg && clipperImg.naturalWidth > 0 && clipperImg.naturalHeight > 0) {
              this.$emit('update-dimensions', {
                width: clipperImg.naturalWidth,
                height: clipperImg.naturalHeight
              });
            } else {
              this.detectAndUpdateDimensions();
            }
          }, 300);
        }
      },
      onHandleZoom(scale) {
        const validScale = parseFloat(scale);
        if (isNaN(validScale)) {
          return;
        }

        const boundedScale = Math.max(0.5, Math.min(validScale, 2));
        
        if (boundedScale !== this.scale) {
          const clipper = this.$refs.clipper;
          if (clipper && clipper.setWH$) {
            clipper.setWH$.next(boundedScale);
          }
          this.$emit('handle-zoom', boundedScale);
        }
      },
      onClipImage() {
        this.$emit('clip-image');
      },
      getClipper() {
        return this.$refs.clipper;
      }
    },
    watch: {
      scale: {
        immediate: true,
        handler(newScale) {
          const validScale = parseFloat(newScale);
          if (isNaN(validScale) || validScale < 0.5 || validScale > 2) {
            this.$emit('handle-zoom', 0.5);
          }
        }
      },
      clipperAvatar: {
        immediate: true,
        handler(newValue) {
          if (newValue) {
            setTimeout(() => {
              this.detectAndUpdateDimensions();
            }, 100);
          }
        }
      }
    },
    mounted() {
      this.detectAndUpdateDimensions();
    }
  }
  </script>

<style lang="scss">
/* Non-scoped styles to ensure they apply to the dialog */
.photo_clipper_dialog {
  background-color: #10182B !important;
  border-radius: 12px !important;
  box-shadow: 0 8px 30px rgba(0, 0, 0, 0.5) !important;
  overflow: hidden !important;
  
  .photo_clipper {
    padding: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    color: #fff;
    position: relative;

    .dialog-close-btn {
      position: absolute;
      top: 10px;
      right: 10px;
      width: 32px;
      height: 32px;
      border-radius: 50%;
      background-color: #282C47 !important;
      color: white !important;
      font-size: 24px !important;
      line-height: 24px !important;
      border: 2px solid #5F6986 !important;
      display: flex !important;
      align-items: center !important;
      justify-content: center !important;
      cursor: pointer !important;
      z-index: 10 !important;
      transition: all 0.3s ease !important;
      padding: 0 !important;
      
      &:hover {
        background-color: #20C4F5 !important;
        transform: scale(1.1) !important;
        box-shadow: 0 4px 12px rgba(32, 196, 245, 0.3) !important;
        border-color: #20C4F5 !important;
      }
    }

    .clipper-fixed {
      margin-bottom: 20px;
      box-shadow: 0 4px 12px rgba(0, 0, 0, 0.3);
      border-radius: 10px;
      overflow: hidden;
      border: 2px solid #5F6986;

      &.basic {
        width: 100% !important;  /* Make clipper fill the available width */
        max-width: 450px !important;
        height: auto !important;
        aspect-ratio: 1 / 1;
      }
    }

    .upload_image_size {
      margin: 10px 0;
      font-size: 16px;
      color: #7481A6;
      font-weight: 500;
      padding: 4px 12px;
      background-color: rgba(95, 105, 134, 0.1);
      border-radius: 30px;
      
      &.err {
        color: #E84D4D;
        background-color: rgba(232, 77, 77, 0.15);
      }
    }

    .resolution_error_text {
      color: #E84D4D;
      font-size: 14px;
      text-align: center;
      margin: 10px auto;
      max-width: 400px;
      width: 90%;
      line-height: 1.4;
      padding: 8px;
    }

    /* Styling the clipper-range */
    .clipper-range {
      width: 90%;
      max-width: 400px;
      margin: 25px 0;
      
      .wrap {
        height: 6px;
        background: #444 !important;
        border-radius: 3px;
      }
      
      .bar {
        background: #20C4F5 !important;
        border-radius: 3px;
      }
      
      .dot {
        background: #fff !important;
        border: 2px solid #20C4F5 !important;
        width: 24px !important;
        height: 24px !important;
        top: -10px !important;
        box-shadow: 0 2px 10px rgba(0, 0, 0, 0.3) !important;
        cursor: pointer;
        
        &:hover, &:active {
          box-shadow: 0 2px 14px rgba(32, 196, 245, 0.5) !important;
          transform: scale(1.1) !important;
        }
      }
    }

    .actions_clipper_photo {
      margin-top: 20px;
      display: flex;
      justify-content: space-around;
      width: 100%;
      
      button {
        min-width: 140px !important;
        margin: 0 10px !important;
        padding: 10px 18px !important;
        border-radius: 68px !important;
        font-weight: 500 !important;
        font-size: 14px !important;
        cursor: pointer !important;
        border: none !important;
        transition: all 0.3s ease !important;
        
        &.tetriatary_btn {
          background-color: transparent !important;
          color: #7481A6 !important;
          border: 1px solid #5F6986 !important;
          
          &:hover {
            background-color: rgba(32, 196, 245, 0.05) !important;
            color: #fff !important;
            border-color: #20C4F5 !important;
          }
        }
        
        &.primary_btn {
          background-color: #20C4F5 !important;
          color: white !important;
          
          &:hover {
            background-color: #20C4F5 !important;
            transform: translateY(-2px) !important;
            box-shadow: 0 4px 12px rgba(32, 196, 245, 0.3) !important;
          }
          
          &.disabled {
            background-color: rgba(95, 105, 134, 0.3) !important;
            color: rgba(255, 255, 255, 0.3) !important;
            cursor: not-allowed !important;
            
            &:hover {
              background-color: rgba(95, 105, 134, 0.3) !important;
              transform: none !important;
              box-shadow: none !important;
            }
          }
        }
      }
    }

    .no_valid_image {
      margin-top: 20px;
      background-color: rgba(232, 77, 77, 0.1);
      border: 1px solid rgba(232, 77, 77, 0.3);
      border-radius: 8px;
      padding: 18px;
      text-align: center;
      
      .header {
        color: #E84D4D;
        margin-bottom: 10px;
        font-size: 20px;
      }
      
      .cnt {
        color: #7481A6;
        font-size: 14px;
        line-height: 1.5;
      }
    }
  }

  .progress_loader {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 300px;
    background-color: rgba(3, 16, 32, 0.8);
  }
}

/* Styling for the main component outside the dialog */
.body {
  .image-wrapper {
    .select_cover_album {
      width: 250px;
      height: 250px;
      background-color: #031020;
      border-radius: 10px;
      display: flex;
      align-items: center;
      justify-content: center;
      overflow: hidden;
      cursor: pointer;
      position: relative;
      border: 2px dashed #5F6986;
      transition: all 0.3s ease;

      &:hover {
        border-color: #20C4F5;
        background: rgba(32, 196, 245, 0.05);
        
        .select_img {
          background-color: #20C4F5;
        }
      }

      &.default_img {
        background: url('~@/assets/img/img-bg.svg') no-repeat center center #031020;
      }

      .cover_select_img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        position: relative;
        z-index: 1;
      }

      .select_img {
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        background-color: #5F6986;
        color: white;
        padding: 8px 0;
        font-size: 14px;
        font-weight: 500;
        text-align: center;
        z-index: 2;
        transition: background-color 0.3s ease;
      }
    }
    
    .description_ {
      display: block;
      padding-left: 20px;
      color: #7481A6;
      font-size: 14px;
      margin-top: 8px;
      line-height: 1.5;
      
      .error-msg {
        color: #E84D4D;
        font-weight: 500;
      }
    }
  }
}
</style>