/**
 * Form validation utility functions
 */

/**
 * Prevents input if title length exceeds 50 characters
 * @param {Event} event - The input event
 */
export const blockTitleInput = (event) => {
  if (event.target.value.length >= 51) {
    if (event.key !== 'Backspace') {
      event.preventDefault();
    }
  }
};

/**
 * Prevents input if description length exceeds 2000 characters
 * @param {Event} event - The input event
 */
export const blockDescriptionTextarea = (event) => {
  if (event.target.value.length >= 2001) {
    if (event.key !== 'Backspace') {
      event.preventDefault();
    }
  }
};

/**
 * Allows only numbers to be entered
 * @param {Event} evt - The keypress event
 * @returns {boolean} - Returns true if the key pressed is a number
 */
export const numbersOnly = (evt) => {
  evt = evt || window.event;
  const charCode = evt.which ? evt.which : evt.keyCode;
  if ((charCode > 31 && (charCode < 48 || charCode > 57)) && charCode !== 46) {
    evt.preventDefault();
  } else {
    return true;
  }
};

/**
 * Prevents default key event
 * @param {Event} event - The key event
 */
export const keyHandler = (event) => {
  event.preventDefault();
}; 