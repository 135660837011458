<template>
  <div class="wrap_progress">
    <div class="progress_line">
      <div class="wrap_line">
        <div class="line">
          <div class="first">
            <div class="active" v-if="currentStep >= 2"></div>
          </div>
          <div class="two">
            <div class="active" v-if="currentStep >= 3"></div>
          </div>
          <div class="three">
            <div class="active" v-if="currentStep === 4"></div>
          </div>
        </div>
      </div>

      <div class="step _info" :class="{'active': currentStep === 1, 'step_success': id && currentStep !== 1}">
        <div class="">
          <div class="status">
            <span>1</span>
          </div>
        </div>
        <label>Info</label>
      </div>
      <div class="step _files" :class="{
        'active': currentStep === 2,
        'step_success': (hasTracks && !uploading) && (hasCoverImage && !changingAvatar) && currentStep !== 2 }">
        <div>
          <div class="status">
            <span>2</span>
          </div>
        </div>
        <label>Files</label>
      </div>

      <div class="step _commerce"
          :class="{'active': currentStep === 3, 'step_success': isFree && currentStep !== 3}">
        <div class="">
          <div class="status">
            <span>3</span>
          </div>
        </div>
        <label>Commerce</label>
      </div>
      <div class="step _review"
          :class="{'active': currentStep === 4}">
        <div>
          <div class="status">
            <span>4</span>
          </div>
        </div>
        <label>Review</label>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "StepNavigation",
  props: {
    currentStep: {
      type: Number,
      required: true
    },
    id: {
      type: [String, Number],
      default: null
    },
    hasTracks: {
      type: Boolean,
      default: false
    },
    uploading: {
      type: Boolean,
      default: false
    },
    hasCoverImage: {
      type: Boolean,
      default: false
    },
    changingAvatar: {
      type: Boolean,
      default: false
    },
    isFree: {
      type: Boolean,
      default: false
    }
  }
}
</script>

<style scoped>
/* Styles will be inherited from the parent component or global styles */
</style>
