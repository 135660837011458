var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"wrap_progress"},[_c('div',{staticClass:"progress_line"},[_c('div',{staticClass:"wrap_line"},[_c('div',{staticClass:"line"},[_c('div',{staticClass:"first"},[(_vm.currentStep >= 2)?_c('div',{staticClass:"active"}):_vm._e()]),_c('div',{staticClass:"two"},[(_vm.currentStep >= 3)?_c('div',{staticClass:"active"}):_vm._e()]),_c('div',{staticClass:"three"},[(_vm.currentStep === 4)?_c('div',{staticClass:"active"}):_vm._e()])])]),_c('div',{staticClass:"step _info",class:{'active': _vm.currentStep === 1, 'step_success': _vm.id && _vm.currentStep !== 1}},[_vm._m(0),_c('label',[_vm._v("Info")])]),_c('div',{staticClass:"step _files",class:{
      'active': _vm.currentStep === 2,
      'step_success': (_vm.hasTracks && !_vm.uploading) && (_vm.hasCoverImage && !_vm.changingAvatar) && _vm.currentStep !== 2 }},[_vm._m(1),_c('label',[_vm._v("Files")])]),_c('div',{staticClass:"step _commerce",class:{'active': _vm.currentStep === 3, 'step_success': _vm.isFree && _vm.currentStep !== 3}},[_vm._m(2),_c('label',[_vm._v("Commerce")])]),_c('div',{staticClass:"step _review",class:{'active': _vm.currentStep === 4}},[_vm._m(3),_c('label',[_vm._v("Review")])])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{},[_c('div',{staticClass:"status"},[_c('span',[_vm._v("1")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticClass:"status"},[_c('span',[_vm._v("2")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{},[_c('div',{staticClass:"status"},[_c('span',[_vm._v("3")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticClass:"status"},[_c('span',[_vm._v("4")])])])
}]

export { render, staticRenderFns }